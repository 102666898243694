<template>
  <team-member-profile
    name="Diane Orsmby"
    qualifications="BSc (Hons)"
    charter="MCSP"
    registration="HCPC Registered: PH42369"
    :pic="require(`@/assets/images/diane.jpg`)"
  >
    <p>
      Diane qualified in 1990 from the University of Manchester. From very early
      in her career she has been interested in human movement and neurological
      conditions.
    </p>
    <p>
      She has worked in a variety of NHS settings within Manchester and the
      Portsmouth area. From 1990 Diane has worked in numerous services and
      locations within the NHS. Diane became a private practitioner in Late
      2013.
    </p>
    <p>
      Diane has a wealth of experience with people who have neurological
      conditions. She has a special interest in stroke rehabilitation and spent
      some time as a team leader on a Stroke Rehabilitation Unit in Salford.
    </p>
    <p>
      Diane has a broad range of experience having worked in a number of teams
      including, hospital acute and rehabilitation wards,outpatients and various
      community rehabilitation teams. She values the transition people can make
      from illness, to a sense of wellbeing within their communities.
    </p>
    <p>
      Diane has attended many post graduate courses including Advanced Bobath
      courses, and continues to have a broad and inclusive view towards
      rehabilitation. She continues to update her skills, most recently with
      connective tissue release techniques, Exercise modalities, balance and
      vestibular rehabilitation, Pilates in relation to neurology, together with
      taping skills, lycra orthotic provision and management of neurological
      conditions.
    </p>
    <p>
      Diane is passionate about delivering an excellent bespoke service to
      clients with all sorts of neurological conditions including MS, Brain
      injury, Parkinsons, spinal pathology and general mobility and balance
      problems.
    </p>
    <p>
      She is keen to work towards a client's individual goals, whether this be a
      short term, pulsed or longer term programme of rehabilitation.
    </p>
    <p>
      In her spare time she enjoys playing netball, gardening and spending time
      with her family and friends.
    </p>
  </team-member-profile>
</template>

<script>
import TeamMemberProfile from "../../layouts/TeamMemberProfile.vue";
export default {
  name: "Diane",
  components: {
    "team-member-profile": TeamMemberProfile,
  },
};
</script>
